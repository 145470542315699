<template>
  <div class="user-info">
    <base-box :topBarIcon="userInfoIcon" topBarLabel="个人信息" box-width="95%" box-height="200px" style="margin-top: 20px;">
      <div slot="content" class="content">
        <div class="content-item required">
          <label>
            <span class="required-mark">*</span>考生姓名</label>
          <el-input placeholder="请填写姓名" v-model="userInfoForm.userName" :disabled="canEdit" maxLength="30" :class="!userInfoForm.userName && 'required'">
          </el-input>
          <p v-if="!userInfoForm.userName && !canEdit" class="required-tip">考生姓名不允许为空</p>
        </div>
        <div class="content-item required">
          <label>
            <span class="required-mark">*</span>考生性别</label>
          <el-radio-group v-model="userInfoForm.sex" :disabled="canEdit">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
          </el-radio-group>
          <p v-if="userInfoForm.sex === '' && !canEdit" class="required-tip">考生性别不允许为空</p>
        </div>
        <div class="content-item required">
          <label>
            <span class="required-mark">*</span>考生毕业院校</label>
          <el-select v-model="userInfoForm.school" filterable placeholder="请选择" :disabled="canEdit">
            <el-option v-for="item in schoolList" :key="item.id" :label="item.schoolName" :value="item.schoolName">
            </el-option>
          </el-select>
          <p v-if="!userInfoForm.school && !canEdit" class="required-tip">考生毕业院校不允许为空</p>
        </div>
        <div class="content-item required">
          <label>
            <span class="required-mark">*</span>考生类型</label>
          <el-select v-model="userInfoForm.studentType" @change="currStudentTypeChange" placeholder="请选择" :disabled="canEdit">
            <el-option v-for="item in studentType" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
          <p v-if="!userInfoForm.studentType && !canEdit" class="required-tip">考生类型不允许为空</p>
        </div>
      </div>
    </base-box>
    <base-box :topBarIcon="scoreIcon" topBarLabel="考生成绩" box-width="95%" box-height="320px">
      <div slot="content" class="content">
        <div class="content-item required" style="width: 30%;">
          <label>文化课</label>
          <el-input v-model="cultureTotal" placeholder="请填写文化课成绩" :disabled="true"></el-input>
        </div>
        <div v-if="userInfoForm.studentType !== 1" class="content-item required" style="width: 30%;">
          <label>
            <span class="required-mark">*</span>
            专业课
          </label>
          <el-input class="maxlength" placeholder="请填写专业课成绩" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="userInfoForm.scoreInfo.majorGrade" maxlength="6" :disabled="canEdit" :max="300" :controls="false"></el-input>
          <p v-if="userInfoForm.studentType !== 1 &&
            userInfoForm.scoreInfo.majorGrade === undefined &&
            !canEdit" class="required-tip">当前考生类型专业课成绩不允许为空</p>
          <p v-if="userInfoForm.scoreInfo.majorGrade &&
            !isNumber2(userInfoForm.scoreInfo.majorGrade)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <div style="width: 30%;"></div>
        </div>
         <div slot="content" class="content">
        <div class="content-item required" style="width: 30%">
          <label>
            <span class="required-mark">*</span>语文</label>
          <el-input v-model="userInfoForm.scoreInfo.languageGrade" :disabled="canEdit" class="maxlength" oninput="value=value.replace(/[^0-9.]/g,'')" :maxlength="scoreLimit" placeholder="请填写成绩"></el-input>
          <p v-if="!userInfoForm.scoreInfo.languageGrade && !canEdit" class="required-tip">语文成绩不允许为空</p>
          <p v-if="userInfoForm.scoreInfo.languageGrade &&
            !isNumber(userInfoForm.scoreInfo.languageGrade, 150)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <div class="content-item required" style="width: 30%">
          <label>
            <span class="required-mark">*</span>数学</label>
          <el-input v-model="userInfoForm.scoreInfo.mathGrade" :disabled="canEdit" class="maxlength" oninput="value=value.replace(/[^0-9.]/g,'')" :maxlength="scoreLimit" placeholder="请填写成绩"></el-input>
          <p v-if="!userInfoForm.scoreInfo.mathGrade && !canEdit" class="required-tip">数学成绩不允许为空</p>
          <p v-if="userInfoForm.scoreInfo.mathGrade &&
            !isNumber(userInfoForm.scoreInfo.mathGrade, 150)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <div class="content-item required" style="width: 30%">
          <label>
            <span class="required-mark">*</span>外语</label>
          <el-input class="maxlength" :maxlength="scoreLimit" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写成绩" v-model="userInfoForm.scoreInfo.englishGrade" :disabled="canEdit"></el-input>
          <p v-if="!userInfoForm.scoreInfo.englishGrade && !canEdit" class="required-tip">英语成绩不允许为空</p>
          <p v-if="userInfoForm.scoreInfo.englishGrade &&
            !isNumber(userInfoForm.scoreInfo.englishGrade, 150)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <div class="content-item required" style="width: 30%">
          <label>物理</label>
          <el-input class="maxlength" :maxlength="scoreLimit" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写成绩" v-model="userInfoForm.scoreInfo.physicsGrade" :disabled="canEdit">
          </el-input>
          <p v-if="userInfoForm.scoreInfo.physicsGrade &&
            !isNumber(userInfoForm.scoreInfo.physicsGrade, 100)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <div class="content-item required" style="width: 30%">
          <label>化学</label>
          <el-input class="maxlength" :maxlength="scoreLimit" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写成绩" v-model="userInfoForm.scoreInfo.chemistryGrade" :disabled="canEdit">
          </el-input>
          <p v-if="userInfoForm.scoreInfo.chemistryGrade &&
            !isNumber(userInfoForm.scoreInfo.chemistryGrade, 100)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <div class="content-item required" style="width: 30%">
          <label>生物</label>
          <el-input class="maxlength" :maxlength="scoreLimit" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写成绩" v-model="userInfoForm.scoreInfo.biologyGrade" :disabled="canEdit">
          </el-input>
          <p v-if="userInfoForm.scoreInfo.biologyGrade &&
            !isNumber(userInfoForm.scoreInfo.biologyGrade, 100)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <div class="content-item required" style="width: 30%">
          <label>历史</label>
          <el-input class="maxlength" :maxlength="scoreLimit" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写成绩" v-model="userInfoForm.scoreInfo.historyGrade" :disabled="canEdit">
          </el-input>
          <p v-if="userInfoForm.scoreInfo.historyGrade &&
            !isNumber(userInfoForm.scoreInfo.historyGrade, 100)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <div class="content-item required" style="width: 30%">
          <label>地理</label>
          <el-input class="maxlength" :maxlength="scoreLimit" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写成绩" v-model="userInfoForm.scoreInfo.geographyGrade" :disabled="canEdit" :max="100" :controls="false">
          </el-input>
          <p v-if="userInfoForm.scoreInfo.geographyGrade &&
            !isNumber(userInfoForm.scoreInfo.geographyGrade, 100)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <div class="content-item required" style="width: 30%">
          <label>政治</label>
          <el-input :maxlength="scoreLimit" class="maxlength" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写成绩" v-model="userInfoForm.scoreInfo.politicsGrade" :disabled="canEdit">
          </el-input>
          <p v-if="userInfoForm.scoreInfo.politicsGrade &&
            !isNumber(userInfoForm.scoreInfo.politicsGrade, 100)" class="required-tip">
            请输入正确的分数
          </p>
        </div>
        <!-- <div v-if="userInfoForm.studentType !== 1" class="content-item required">
          <label>
            <span class="required-mark">*</span>
            专业课
          </label>
          <el-input class="maxlength" placeholder="请填写专业课成绩" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="userInfoForm.scoreInfo.majorGrade" maxlength="6" :disabled="canEdit" :max="300" :controls="false"></el-input>
          <p v-if="userInfoForm.studentType !== 1 &&
            userInfoForm.scoreInfo.majorGrade === undefined &&
            !canEdit" class="required-tip">当前考生类型专业课成绩不允许为空</p>
          <p v-if="userInfoForm.scoreInfo.majorGrade &&
            !isNumber2(userInfoForm.scoreInfo.majorGrade)" class="required-tip">
            请输入正确的分数
          </p>
        </div> -->
      </div>
    </base-box>
    <div>
      <el-button v-if="canEdit" type="primary" class="btn edit-btn" @click="openEdit">编辑</el-button>
      <template v-else>
        <el-button type="primary" class="btn" @click="updateUserInfo">提交</el-button>
        <el-button v-if="!onlySubmit" class="btn" @click="cancleEdit">取消</el-button>
      </template>
    </div>
  </div>
</template>

<script>
import RegExp from "@/utils/regExp";
import { getUserId } from "@/utils/auth";
import {
  getUserInfoById,
  commitUserInfo,
  editUserInfo,
  getSchoolList
} from "@/api/user";
import BaseBox from "@/components/BaseBox/index.vue";

const globalScoreIcon = require("@/assets/images/icon_score@3x.png");

export default {
  name: "UserInfo",
  components: {
    BaseBox
  },
  data() {
    return {
      scoreLimit: 5,
      onlySubmit: false,
      schoolList: [],
      canEdit: true,
      userInfoIcon: `${process.env.BASE_URL}icon_userInfo@3x.png`,
      scoreIcon: globalScoreIcon,
      userInfoForm: {
        userName: "",
        school: "",
        source: 1,
        sex: null,
        studentType: null,
        scoreInfo: {
          cultureGrade: 0, // 文化课
          languageGrade: "", // 语文
          mathGrade: "", // 数学
          englishGrade: "", // 英语
          physicsGrade: null, // 物理
          chemistryGrade: null, // 化学
          biologyGrade: null, // 生物
          historyGrade: "", // 历史
          geographyGrade: "", // 地理
          politicsGrade: "", // 政治
          majorGrade: "", // 专业
          userId: ""
        }
      },
      studentType: [
        {
          id: 1,
          label: "文化课考生"
        },
        {
          id: 2,
          label: "美术生"
        },
        {
          id: 3,
          label: "体育生"
        },
        {
          id: 4,
          label: "文学编导考生"
        },
        // {
        //   id: 5,
        //   label: "书法类考生"
        // },
        // {
        //   id: 6,
        //   label: "舞蹈类考生"
        // },
        // {
        //   id: 7,
        //   label: "音乐生"
        // },
        // {
        //   id: 8,
        //   label: "播音主持类考生"
        // },
        // {
        //   id: 9,
        //   label: "影视戏剧表演生"
        // },
        // {
        //   id: 10,
        //   label: "服装表演类考生"
        // },
        // {
        //   id: 11,
        //   label: "航空服务艺术类考生"
        // },
        // {
        //   id: 12,
        //   label: "摄影类考生"
        // }
      ],
      userInfoFormBak: {}
    };
  },
  computed: {
    cultureTotal() {
      let total = 0;
      const containFiled = [
        "languageGrade",
        "mathGrade",
        "englishGrade",
        "physicsGrade",
        "chemistryGrade",
        "biologyGrade",
        "historyGrade",
        "geographyGrade",
        "politicsGrade"
      ];
      for (let i = 0; i < containFiled.length; i += 1) {
        const str = containFiled[i];
        if (this.userInfoForm.scoreInfo[str]) {
          total += Number(this.userInfoForm.scoreInfo[str]);
        }
      }
      return total.toString().indexOf(".") === -1 ? total : total.toFixed(1);
    }
  },
  watch: {
    canEdit(value) {
      if (!value) {
        this.getSchool();
      }
    }
  },
  mounted() {
    if (!JSON.parse(localStorage.getItem("user")).userName) {
      this.onlySubmit = true;
      this.canEdit = false;
    } else {
      this.onlySubmit = false;
      this.getUserInfo();
    }
  },
  methods: {
    currStudentTypeChange(val) {
      // 监听考生类型发生变化
      console.log(val);
      if (this.userInfoForm.studentType > 4) {
        //----------------------------------
        this.$message.messageError("当前类型考生暂无推荐方案");
        return;
      }
    },
    isNumber(val, limit) {
      return RegExp.number1.test(val) && val <= limit;
    },
    isNumber2(val) {
      if (this.userInfoForm.studentType === 3 && val > 100) {
        return false;
      }
      if (this.userInfoForm.studentType !== 3 && val > 300) {
        return false;
      }
      return RegExp.number2.test(val);
    },
    getSchool() {
      getSchoolList().then(res => {
        if (res.code === 0) {
          this.schoolList = res.data;
        } else {
          this.$message.messageError(res.message);
        }
      });
    },
    cancleEdit() {
      this.userInfoForm = JSON.parse(JSON.stringify(this.userInfoFormBak));
      this.canEdit = true;
    },
    openEdit() {
      this.canEdit = false;
      // 需要拷贝出一个新的对象
      this.userInfoFormBak = JSON.parse(JSON.stringify(this.userInfoForm));
    },
    getUserInfo() {
      const params = {
        userId: getUserId()
      };
      getUserInfoById(params).then(res => {
        if (res.code === 0) {
          const result = res.data;
          this.userInfoForm = {
            ...result
          };
        } else {
          this.$message.messageError(res.message);
        }
      });
    },
    updateUserInfo() {
      const params = {
        userId: getUserId(),
        ...this.userInfoForm
      };
      if (!params.userName.trim()) {
        this.$message.messageError("请填写正确的用户名");
        return;
      }
      const required = document.querySelectorAll(".required-tip");
      if (required.length > 0) {
        this.$message.messageError("请正确完整填写相应数据");
        return;
      }
      const sixofthree = [
        "physicsGrade",
        "chemistryGrade",
        "biologyGrade",
        "historyGrade",
        "geographyGrade",
        "politicsGrade"
      ];
      let requiredNum = 0;
      sixofthree.forEach(item => {
        if (params.scoreInfo[item]) {
          requiredNum += 1;
        }
      });
      if (requiredNum > 3) {
        this.$message.messageError("非必填科目中只能填写三科");
        return;
      }
      if (
        params.studentType !== 1 &&
        params.scoreInfo.majorGrade !== 0 &&
        !params.scoreInfo.majorGrade
      ) {
        this.$message.messageError("请填写专业课");
        return;
      }
      if (params.studentType === 3 && params.scoreInfo.majorGrade > 100) {
        this.$message.messageError("专业课成绩填写有误");
        return;
      }
      if (params.studentType !== 3 && params.scoreInfo.majorGrade > 300) {
        this.$message.messageError("专业课成绩填写有误");
        return;
      }
      if (this.userInfoForm.studentType > 4) {
        //----------------------------------
        this.$message.messageError("当前类型考生暂无推荐方案");
        return;
      }
      params.scoreInfo.userId = params.userId;
      params.scoreInfo.cultureGrade = this.cultureTotal;
      if (params.studentType === 1) {
        params.scoreInfo.majorGrade = null;
      }
      if (!params.scoreInfo.id) {
        commitUserInfo(params).then(res => {
          if (res.code === 0) {
            this.$message.messageSuccess("提交成功");
            this.$store.commit("SET_USERNAME", res.data.userName);
            localStorage.setItem("user", JSON.stringify(res.data));
            this.canEdit = true;
          } else {
            this.$message.messageError(res.message);
          }
        });
      } else {
        editUserInfo(params).then(res => {
          if (res.code === 0) {
            this.$message.messageSuccess("编辑成功");
            this.$store.commit("SET_USERNAME", params.userName);
            localStorage.setItem("user", JSON.stringify(params));
            this.canEdit = true;
          } else {
            this.$message.messageError(res.message);
          }
        });
      }
      this.$router.push("/home");
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
}
.content-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  // height: 60px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;

  .el-input {
    width: 215px;
  }
  /deep/ input {
    border: none;
    text-align: right;
  }
}

.btn {
  width: 120px;
  height: 48px;
  // margin: 40px 20px 73px;
  margin-top: 10px;
}

.edit-btn {
  width: 240px;
}

.required {
  position: relative;
}
.required-mark {
  position: absolute;
  top: 25px;
  left: -10px;
  color: rgb(255, 0, 0);
}
.required-tip {
  position: absolute;
  top: 65px;
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #ff0000;
}
</style>
